import type { ProductCardHorizontalProps } from '@design-system/components/UI/UIProductCardHorizontal.props'
import type {
  Assets,
  ProductTileProps,
} from '@design-system/components/UI/UIProductTile.props'

const images: Assets[] = [
  {
    URL: '/images/product-tile-sample.png',
    type: 'TResponsiveImage',
  },
]

export const cards: ProductTileProps[] = [...Array(2).keys()].map(() => {
  return {
    product: {
      name: "DÉCOLLETÉ D'ORSAY IN PELLE CON LOGO ALL-OVER",
      sku: 'GW000152_TE10831_UB115',
      linkToProduct: '#',
      brand: 'GIORGIO-ARMANI',
      isAvailable3d: true,
      images: images,
      price: {
        originalPrice: 1600,
      },
      labels: ['SUSTAINABLE', 'LIMITED EDITION', 'NEVE'],
      inventory: {
        isAvailableOnline: true,
        isAvailableInStore: true,
      },
      defaultColor: 'Red',
    },
  }
})

export const horizontalProductCard: ProductCardHorizontalProps = {
  id: '123',
  info: {
    name: 'Straight-cut black trousers with waistband',
    price: 1000,
    oldPrice: 2000,
    color: 'Green',
    size: 'M',
    percentageDiscount: '-50%',
  },
  path: '#',
  quantity: 1,
  itemTotalPrice: 1000,
  image: '/images/product-card-horizontal-placeholder.png',
  metadata: 'giorgio-armani',
  available: true,
  skuCode: 'skucode',
}
